.leaflet-container {
	height: 100%;
	width: 100%;
}

.glow-icon {
	transition: transform 0.2s, box-shadow 0.2s;
}

.leaflet-icon:hover .glow-icon {
	transform: scale(1.1);
	/* Optional: Enlarge the icon on hover */
	box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.7);
	/* Add a glowing effect on hover */
}
/* Scrollbar styles */
* {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color for Firefox */
}

::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
}
