/* Global variables see theme.js */
.uplot  {
  font-family: var(--ox-customVariables---font-family); 
  line-height:  1.5;
  width:  min-content;
  background-color: var(--ox-palette-background-paper); 
  /* margin-left: -25px; */
}

.u-title  {
  text-align:  left;
  /* margin-left: 25px; */
  font-size:  18px;
  font-weight:  bold;
  padding-bottom: 1rem;
}
.u-wrap  {
  position:  relative;
  user-select:  none;
  /* border: solid var(--ox-palette-info-light) 0.5px; */
}
.u-over,  .u-under  {
  position:  absolute;
}
.u-under  {
  overflow:  hidden;
}
.uplot canvas  {
  display:  block;
  position:  relative;
  width:  100%;
  height:  100%;
}
.u-axis  {
  position:  absolute;
}
.u-legend  {
  font-size:  13px;
  margin-right: auto;
  text-align:  left;
  /* display: none; */
}
.u-legend .u-marker  {
  width:  0.9em;
  height:  0.2em;
  border-width: 2px;
  margin-right:  6px;
  background-clip:  padding-box !important;
}
.u-legend th > *  {
  vertical-align:  middle;
  display:  inline-block;
}
.u-legend.u-inline .u-value { 
  width: 80px; 
  text-align: left; 
} 
/* used fpr tooltip*/
.u-label  {
  width: 220px;
}

.u-marker-ext  {
  width: 20px;
}

.u-series > *  {
  /* padding:  4px; */
  padding:  4px;
}
.u-series th  {
  cursor:  pointer;
}
.u-legend .u-off > *  {
  opacity:  0.3;
}
.u-select  {
  background:  rgba(0, 0, 0, 0.07);
  position:  absolute;
  pointer-events:  none;
}
.u-cursor-x,  .u-cursor-y  {
  position:  absolute;
  left:  0;
  top:  0;
  pointer-events:  none;
  will-change:  transform;
}
.u-hz .u-cursor-x,  .u-vt .u-cursor-y  {
  height:  100%;
  border-right-color: var(--ox-palette-secondary-main);
  border-right-width: 1px;
  border-right-style: dashed;
}
.u-hz .u-cursor-y,  .u-vt .u-cursor-x  {
  width:  100%;
  border-bottom:  1px dashed var(--ox-palette-secondary-main);
}
.u-hz  {
  display: flex;
  flex-direction: row-reverse;  
  justify-content: space-between;  
  align-items: center;  
  width: 100%;
}
.u-cursor-pt  {
  position:  absolute;
  top:  0;
  left:  0;
  border-radius:  50%;
  border:  0 solid;
  pointer-events:  none;
  will-change:  transform;
  /*this has to be !important since we set inline "background" shorthand */background-clip:  padding-box !important;
}
.u-axis.u-off,  .u-select.u-off,  .u-cursor-x.u-off,  .u-cursor-y.u-off,  .u-cursor-pt.u-off  {
  display:  none;
}

/* .u-series  {
  width: 300px;
} */
/* .u-series  {
  width: 30px;
} */

