.ql-container {
    box-sizing: border-box;
    font-family: var(--ox-customVariables---font-family);
    font-size: 13px;
    height: 100%;
    margin: 0;
    position: relative;
}

.ql-container.ql-snow {
    border: none;
    /* Override the border setting */
    border-top: 1px solid #ccc;
}
.ql-container.ql-snow.newEntry {
    /* Your additional styles for the new subclass */
    /* For example: */
    background-color: #f0f0f0;
    padding: 10px;
  }
.ql-toolbar.ql-snow {
    border: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    font-family: var(--ox-customVariables---font-family);
    padding: 8px;
}

.ql-editor {
    max-height: 350px;
    border-left: 0px;
    border-right: 0px;
    position: relative;
}
.ql-snow .ql-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #ddd;
    color: #444;
    padding: 5px 12px;
    white-space: nowrap;
    margin-left: 100px;
    position: absolute;
  
}

  